<template>
  <div class="autocomplete">
    <ClientOnly>
      <VueSelect
        ref="vueSelectComponentRef"
        v-model="model"
        :options="items"
        :get-option-label="label"
        :clearable="clearable ?? false"
        :searchable="searchable"
        :placeholder="placeholder"
        @open="scrollIntoView()"
      >
        <template #no-options><div /></template>
      </VueSelect>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts" generic="T">
import 'vue-select/dist/vue-select.css';
// eslint-disable-next-line import/no-named-as-default
import VueSelect from 'vue-select';

withDefaults(
  defineProps<{
    items: T[];
    label?: (item: T) => string;
    placeholder?: string;
    clearable?: boolean;
    searchable?: boolean;
  }>(),
  {
    label: undefined,
    placeholder: undefined,
    searchable: true,
  },
);

const model = defineModel<T>();

const vueSelectComponentRef = ref<ComponentPublicInstance>();

function scrollIntoView() {
  // only for small viewports
  if (!window.matchMedia('(max-width: 899px)').matches) {
    return;
  }

  if (vueSelectComponentRef.value) {
    vueSelectComponentRef.value?.$el?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
}

function focus() {
  nextTick(() => {
    const input =
      (vueSelectComponentRef.value?.$el as HTMLElement).querySelector(
        'input',
      ) ?? null;
    if (input) {
      input.focus();
    }
  });
}

defineExpose({
  focus,
});
</script>

<style scoped lang="scss">
.autocomplete {
  // Custom var definitions
  --local-radius: 10px;
  --local-color: var(--color-stone-100);
  --local-background-color: rgba(20, 20, 20, 0.6);
  --local-border-radius: var(--local-radius);
  --local-dropdown-background-color: rgba(34, 34, 34, 0.96);

  // Variable overrides for vue select
  --vs-line-height: 1.1;
  --vs-dropdown-bg: var(--local-dropdown-background-color);
  --vs-dropdown-color: var(--local-color);
  --vs-dropdown-option-padding: 18px 16px;
  --vs-dropdown-option--active-bg: var(--color-stone-900);
  --vs-dropdown-option--active-color: var(--local-color);
  --vs-controls-color: var(--local-color);

  &.-lime {
    --local-color: var(--color-lime-400);
  }

  &.-blaze {
    --local-color: var(--color-blaze-500);
  }

  :deep(.v-select) {
    &.vs--open {
      > .vs__dropdown-toggle {
        background-color: var(--local-dropdown-background-color);
      }
    }

    &:not(.vs--open):has(.vs__selected) .vs__dropdown-toggle {
      background-image: none;
    }

    > .vs__dropdown-toggle {
      @extend .text-sm;

      line-height: 1.1;
      min-height: calc(1em + 2.24rem);
      background-color: var(--local-background-color);
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.99765 7.23001L0.755005 2.98737L1.96416 1.77822L4.99767 4.81173L8.03305 1.77635L9.2422 2.9855L4.99956 7.22814L4.99765 7.23001Z' fill='%23A8A8A8'/%3E%3C/svg%3E%0A");
      background-size: 0.625rem;
      background-repeat: no-repeat;
      background-position: center right 1rem;
      padding: 0;
      border-radius: var(--local-border-radius);
      border: none;

      > .vs__selected-options {
        padding: 0;
        position: relative;

        > .vs__selected {
          border: 0;
          margin: 0;
          padding: 8px 16px;
          color: var(--local-color);
          z-index: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > .vs__search {
          @extend .text-sm;

          background-color: transparent;
          margin: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 1.12rem 1rem;
          z-index: 0;
          line-height: 1.1;

          &::placeholder {
            color: var(--color-stone-500);
          }
        }
      }

      > .vs__actions {
        padding-top: 0;

        > .vs__open-indicator {
          color: var(--color-stone-300);
          background-color: transparent;
          fill: none;
          height: 100%;
        }

        > .vs__clear {
          @extend .text-sm;

          position: relative;
          width: 16px;
          height: 100%;
          margin: 0 -0.5rem 0 0;
          opacity: 0.6;
          z-index: 100;

          &:before {
            position: absolute;
            content: '';
            left: -0.5rem;
            right: -0.5rem;
            top: 0;
            height: 100%;
          }
        }
      }
    }

    > .vs__dropdown-menu {
      background: var(--local-dropdown-background-color);
      color: var(--local-color);
      //backdrop-filter: blur(1rem); // Doesn't work inside fixed elements
      border: none;
      border-radius: 0 0 var(--local-radius) var(--local-radius);
      white-space: wrap;

      .vs__dropdown-option {
        @extend .text-sm;

        padding: 10px 16px;
        line-height: 1.1;
        color: var(--local-color);
        white-space: wrap;

        &:not(:first-child) {
          border-top: 1px solid #202020;
        }
      }
    }

    &.vs--open {
      --local-border-radius: var(--local-radius) var(--local-radius) 0 0;

      .vs__selected-options > .vs__selected {
        opacity: 0.75;
      }
    }
  }
}
</style>
